<template>
    <TitlePage title="Detail Produk"/>
    <CardContent 
        isUseback
        @action-back="backToList"
    >
        <div class="loding" v-if="loading">
            <v-progress-circular class="mt-2" color="primary" model-value="20" indeterminate></v-progress-circular>
        </div>
        <div class="productDetail" v-else>
            <v-row>
                <v-col cols="12" md="4" lg="4" v-if="detailProduct?.listPicture.length == 1">
                    <v-img
                        v-for="img in detailProduct?.listPicture"
                        :key="img.pictureId"
                        aspect-ratio="1"
                        cover
                        :src="img.url"
                    ></v-img>
                </v-col>
                <v-col cols="12" md="4" lg="4" v-else-if="detailProduct?.listPicture.length == 0">
                    <v-img
                        aspect-ratio="1"
                        cover
                        :src="noImg"
                    ></v-img>
                </v-col>
                <v-col cols="12" md="4" lg="4" v-else>
                    <v-carousel>
                        <v-carousel-item
                            v-for="(item,i) in detailProduct?.listPicture"
                            :key="i"
                            :src="item.url"
                            cover
                        ></v-carousel-item>
                    </v-carousel>
                </v-col>
                <v-col cols="12" md="8" lg="8" >
                    <v-table density="compact" hover>
                        <tr class="custom-row">
                            <td width="120px">Nama produk</td>
                            <td>:</td>
                            <td>{{ detailProduct?.namaProduk
                             }}</td>
                        </tr>
                        <tr class="custom-row">
                            <td width="120px">Kategori</td>
                            <td>:</td>
                            <td>{{ detailProduct?.namaKategori }}</td>
                        </tr>
                        <tr class="custom-row">
                            <td width="120px">Sub Kategori</td>
                            <td>:</td>
                            <td>{{ detailProduct?.namaSubKategori }}</td>
                        </tr>
                        <tr class="custom-row">
                            <td width="120px">Harga Normal</td>
                            <td>:</td>
                            <td>Rp {{ formatRupiah(detailProduct?.hargaNormal) }}</td>
                        </tr>
                        <tr class="custom-row">
                            <td width="120px">Harga Promo</td>
                            <td>:</td>
                            <td>Rp {{ formatRupiah(detailProduct?.hargaPromo) }}</td>
                        </tr>
                        <tr class="custom-row">
                            <td width="120px">Stok</td>
                            <td>:</td>
                            <td>{{ detailProduct?.qtyStock }}</td>
                        </tr>
                        <tr class="custom-row">
                            <td width="120px">Terjual</td>
                            <td>:</td>
                            <td>{{ detailProduct?.qtyTerjual }}</td>
                        </tr>
                        <tr class="custom-row">
                            <td width="120px">Berat</td>
                            <td>:</td>
                            <td>{{ detailProduct?.beratGram }} gr</td>
                        </tr>
                        <tr class="custom-row">
                            <td width="120px">Status</td>
                            <td>:</td>
                            <td>
                                <v-chip size="small" color="success" v-if="detailProduct?.status === 'AKTIF'">
                                    {{ detailProduct?.status }}
                                </v-chip>
                                <v-chip size="small" color="warning" v-else>
                                    {{ detailProduct?.status }}
                                </v-chip>
                            </td>
                        </tr>
                        <tr class="custom-row">
                            <td width="120px">Deskripsi</td>
                            <td>:</td>
                            <td>{{ detailProduct?.deskripsi }}</td>
                        </tr>
                    </v-table>
                </v-col>
            </v-row>
        </div>
        
    </CardContent>

</template>
<script>
import TitlePage from '@/components/TitlePage.vue';
import CardContent from '@/components/CardContent.vue';
import { getProdukById } from '@/api/ApiService.js';
import noImg from '@/assets/images/not-found.jpg';
import { formatNumber } from '@/library/util.js'

export default {
    name: 'ProductPageDetail',
    data() {
        return {
            detailProduct: null,
            noImg: noImg,
            loading: false
        }
    },
    components: {
        TitlePage,
        CardContent
    },
    mounted() {
        this.fetchDetailProduct();
    },
    methods: {
        async fetchDetailProduct() {
            const id = this.$route.params.id;
            this.loading = true;
            try {
                const res = await getProdukById(id);
                this.detailProduct = res.data.data;
                this.loading = false;
            } catch(e) {
                console.error(e);
            }
        },
        backToList() {
            this.$router.push({name: 'ProductPage'});
        },
        formatRupiah(price) {
            return formatNumber(price);
        }
    }
}
</script>

<style lang="scss" scoped>
.loding {
    width: 100%;
    text-align: center;
    padding: 20px 0px;
}
.productDetail {
    .custom-row {
        height: 40px;
    }
}
</style>