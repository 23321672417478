<template>
    <div v-if="isMobile">
        <div class="menuMobile" v-if="isOpen">   
            <div class="menuContent">
                <MenuAside />
            </div>
            <div class="logoutButton">
                <v-btn block @click="logoutAction">
                    Logout
                </v-btn>
            </div>
        </div>
    </div>
    
    <div v-else>
        <v-navigation-drawer  
            v-if="isOpen"
            class="navigationSideBar"
        >
            <LogoOleb />
            <MenuAside />
            <template v-slot:append>
                <div class="pa-2">
                    <v-btn block @click="logoutAction">
                    Logout
                    </v-btn>
                </div>
            </template>
        </v-navigation-drawer>
    </div>
    
</template>
<script>
import MenuAside from './MenuAside.vue';
import LogoOleb from './LogoOleb.vue'
import { logout } from '@/api/ApiService';
import { isMobileDevice } from '../library/util.js'

export default {
    name: 'SideBar',
    props: {
        isOpen: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            isMobile: false
        }
    },
    components: {
        LogoOleb,
        MenuAside
    },
    mounted() {
        let isMobileDevices = isMobileDevice();
        this.isMobile = isMobileDevices
    },
    methods: {
        logoutAction() {
            logout();
            location.reload();
            this.$router.push({ name: 'loginPage' });
        }
    }
}
</script>

<style lang="scss" scoped>
.navigationSideBar {
    padding: 0px;
}
.menuMobile {
    width: 100%;
    position: absolute;
    height: calc(100vh - 48px);
    background: #ffffff;
    top: 48px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 99;
}

.menuContent {
  flex: 1;
  overflow-y: auto;
}

.logoutButton {
  padding: 16px;
}
</style>