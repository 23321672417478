<template>
    <TitlePage title="Profil Toko"/>
    <CardContent>
        <div class="store-profile" >
            <v-row >
                <v-col cols="12" md="6" lg="6">
                    <v-text-field
                        label="Nama Toko"
                        class="input-form"
                        variant="underlined"
                        v-model="form.namaToko"
                    ></v-text-field>
                    <v-textarea 
                        label="Deskripsi Toko" 
                        variant="underlined"
                        class="input-form"
                        row="7"
                        v-model="form.keterangan"
                    ></v-textarea>
                    <v-select
                        :label="loadingProvince ? 'Loading...' : 'Provinsi'"
                        :items="provinceLists"
                        item-title="namaPropinsi"
                        item-value="idPropinsi"
                        v-model="selectedProvince"
                        return-object
                        variant="underlined"
                    ></v-select>
                    <v-select
                        :label="loadingState ? 'Loading...' : 'Kabupaten/Kota'"
                        :items="stateLists"
                        item-title="namaKota"
                        item-value="idKota"
                        v-model="selectedState"
                        return-object
                        variant="underlined"
                    ></v-select>
                    <v-select
                        :label="loadingKecamatan ? 'Loading...' : 'Kecamatan'"
                        :items="kecamatanLists"
                        item-title="namaKecamatan"
                        item-value="idKecamatan"
                        v-model="selectedKecamatan"
                        return-object
                        variant="underlined"
                    ></v-select>
                    <v-textarea 
                        label="Alamat Toko" 
                        variant="underlined"
                        class="input-form"
                        row="7"
                        v-model="form.alamat"
                    ></v-textarea>
                    <v-text-field
                        label="Kode Pos"
                        class="input-form"
                        variant="underlined"
                        v-model="form.kodePos"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="6">
                    <v-text-field
                        label="Bank"
                        class="input-form"
                        variant="underlined"
                        v-model="form.bank"
                    ></v-text-field>
                    <v-text-field
                        label="No Rekening"
                        class="input-form"
                        variant="underlined"
                        v-model="form.noRekening"
                    ></v-text-field>
                    <v-text-field
                        label="Nama Pemilik Rekening"
                        class="input-form"
                        variant="underlined"
                        v-model="form.namaPemilikRekening"
                    ></v-text-field>
                    <v-select
                        :label="loadingKurir ? 'Loading...' : 'Pilihan Kurir'"
                        :items="kurirLists"
                        v-model="selectedKurir"
                        chips
                        item-title="namaKurir"
                        item-value="kurirId"
                        multiple
                        variant="underlined"
                    ></v-select>
                </v-col>
            </v-row>
            <div class="d-flex flex-row-reverse">
                <v-sheet>
                    <v-btn  
                        color="info"
                        @click="saveData"
                        :disabled="loading"
                    >
                        <v-icon icon="mdi-content-save"></v-icon>
                        {{ loading ? 'Menyimpan...' : 'Simpan Perubahan' }}
                    </v-btn>
                </v-sheet>
            </div>
        </div>
    </CardContent>
</template>

<script>
import TitlePage from '@/components/TitlePage.vue';
import CardContent from '@/components/CardContent.vue';
import { 
    updateStore, 
    getTokoByUser,
    getKurirAll,
    getProvince,
    getState,
    getKecamatan,
} from '@/api/ApiService.js';

export default {
    name: 'ProfilePage',
    data() {
        return {
            form: {
                alamat: null,
                bank: null,
                gambarLogo: null,
                kecamatan: null,
                kecamatanId: null,
                keterangan: null,
                kodePos: null,
                kota: null,
                kotaId: null,
                kurir: [
                    {
                        kodeKurir: null,
                        namaKurir: null
                    }
                ],
                namaPemilikRekening: null,
                namaToko: null,
                noRekening: null,
                provinsi: null,
                provinsiId: null,
                tokoId: null
            },
            loading: false,
            kurirLists: [],
            selectedKurir: null,
            loadingKurir: false,
            provinceLists: [],
            loadingProvince: false,
            selectedProvince: null,
            stateLists: [],
            loadingState: false,
            selectedState: null,
            kecamatanLists: [],
            loadingKecamatan: false,
            selectedKecamatan: null,
        }
    },
    components: {
        TitlePage,
        CardContent
    },
    watch: {
        selectedKurir: function(value) {
            const select = value.map((val) => {
                const kurir = this.kurirLists.find((item) => item.kurirId == val);
                const map = {
                    kodeKurir: kurir.kurirId,
                    namaKurir: kurir.namaKurir
                }
                return map;
            })
            this.form.kurir = select;
        },
        selectedProvince: function(value) {
            this.form.provinsiId = value.idPropinsi;
            this.form.provinsi = value.namaPropinsi;
        },
        selectedState: function(value) {
            this.form.kotaId = value.idKota;
            this.form.kota = value.namaKota;
            this.fetchKecamatan(value.idKota);
        },
        selectedKecamatan: function(value) {
            this.form.kecamatanId = value.idKecamatan;
            this.form.kecamatan = value.namaKecamatan;
        }
    },
    mounted() {
        this.fetchProfileToko();
        this.fetchKurir();
        this.fetchProvince();
    },
    methods: {
        async saveData() {
            this.loading = true;
            try {
                await updateStore(this.form);
                this.loading = false;
                this.$router.push('/');
            } catch(e) {
                console.error(e);
            }
        },
        async fetchProfileToko() {
            try {
                const res = await getTokoByUser();
                const data = res.data.data;
                this.form = data;
                this.$store.commit('setStoreProfile', res.data.data);
                this.fetchState(data.provinsiId);
                const provinceSelect = {
                    namaPropinsi: data.provinsi,
                    idPropinsi: data.provinsiId
                }
                this.form.kota = data.kota;
                const stateSelect = {
                    idKota: data.kotaId,
                    namaKota: data.kota
                }
                this.selectedProvince = provinceSelect;
                this.selectedState = stateSelect;
                this.fetchKecamatan(data.kotaId);
                const selectKecamatan = {
                    idKecamatan: data.kecamatanId,
                    namaKecamatan: data.kecamatan
                }
                this.selectedKecamatan = selectKecamatan;
                if(data.kurir.length > 0) {
                    const selectKurir = data.kurir.map((item) => item.kodeKurir);
                    this.selectedKurir = selectKurir;
                }
                
            } catch(e) {
                console.error(e);
            }
        },
        async fetchKurir() {
            this.loadingKurir = true;
            try {
                const res = await getKurirAll();
                this.kurirLists = res.data.data.data;
                this.loadingKurir = false;
            } catch(e) {
                console.error(e);
            }
        },
        async fetchProvince() {
            this.loadingProvince = true;
            try {
                const res = await getProvince();
                this.provinceLists = res.data.data;
                this.loadingProvince = false;
            } catch(e) {
                console.error(e);
            }
        },
        async fetchState(id) {
            this.loadingState = true;
            try{
                const res = await getState(id);
                this.stateLists = res.data.data;
                this.loadingState = false;
            }catch(e) {
                console.error(e);
            }
        },
        async fetchKecamatan(id) {
            this.loadingKecamatan = true;
            try{
                const res = await getKecamatan(id);
                this.kecamatanLists = res.data.data;
                this.loadingKecamatan = false;
            }catch(e) {
                console.error(e);
            }
        }
    },
    computed: {
        myProfile() {
            return this.$store.getters['getStoreProfile'] ?? null;
        }
    }
}
</script>

<style lang="scss" scoped>
.store-profile {

}
</style>