<template>
    <TitlePage title="Data Pesanan"/>
    <CardContent>
        <v-table density="compact" hover>
            <thead>
                <tr>
                    <th class="text-left">
                        Nama Produk
                    </th>
                    <th class="text-left">
                        Stok
                    </th>
                    <th class="text-left">
                        Harga Normal
                    </th>
                    <th class="text-left">
                        Status
                    </th>
                    <th class="text-left">
                    </th>
                </tr>
            </thead>
        </v-table>
    </CardContent>
</template>
<script>
import TitlePage from '@/components/TitlePage.vue';
import CardContent from '@/components/CardContent.vue';

export default {
    name: 'PesananPage',
    components: {
        TitlePage,
        CardContent
    }
}
</script>