<template>
<v-container fluid>
    <v-card >
        <div class="d-flex flex-row-reverse" v-if="isUseback">
            <v-sheet class="ma-2 pa-2">
                <v-btn 
                    variant="text" 
                    density="compact" 
                    color="info"
                    @click="backButtonAction"
                >
                    <v-icon icon="mdi-arrow-left"></v-icon>
                    Kembali
                </v-btn>
            </v-sheet>
        </div>
        <v-row>
            <v-col cols="12">
                <div class="contentOfCard">
                    <slot></slot>
                </div>
            </v-col>
        </v-row>
    </v-card>
</v-container>
</template>
<script>
export default {
    name: 'CardContent',
    props: {
        isUseback: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        backButtonAction() {
            this.$emit('action-back');
        }
    }
}
</script>
<style lang="scss" scoped>
.contentOfCard {
    padding: 21px 16px;
}
</style>