<template>
    <v-app-bar
        color="primary"
        density="compact"
        elevation="0"
    >
        <template v-slot:prepend>
            <v-app-bar-nav-icon @click="clickAppBarIcon"></v-app-bar-nav-icon>
        </template>

        <template v-slot:append>
            <AvatarHeader :title="storeName">
                <v-menu activator="parent">
                    <v-list>
                        <v-list-item
                            v-for="(item, index) in items"
                            :key="index"
                            :value="index"
                            :to="{path: 'profile'}"
                        >
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </AvatarHeader>
        </template>
    </v-app-bar>
</template>
<script>
import AvatarHeader from './AvatarHeader.vue';

export default {
    name: 'HeaderNav',
    props: {
        storeName: {
            type: String,
            default: 'Oleb Sai'
        }
    },
    data() {
        return {
            items: [
                { title: 'Profile' },
            ],
        }
    },
    components: {
        AvatarHeader
    },
    methods: {
        clickAppBarIcon() {
            this.$emit('app-icon-click');
        }
    }
}
</script>

<style lang="scss">
</style>