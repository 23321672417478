<template>
    <div class="logo-image">
        <img src="../assets/images/logo-oleb.png"/>
        <span class="title">Olebsai</span>
    </div>
</template>
<script>
export default {
    name: 'LogoOleb'
}
</script>
<style lang="scss" scoped>
.logo-image {
    display: flex;
    background-color: #D40431;
    height: 48px;
    img {
        height: 35px;
        margin-top: 5px;
        margin-left: 16px;
    }
    .title {
        margin-top: 10px;
        margin-left: 8px;
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
    }
}
</style>