<template>
    <div class="avatarHeader">
        <v-avatar color="#ddd">
            <span class="text-h6">{{ trimName(title) }}</span>
        </v-avatar>
        <slot></slot>
    </div>
    
</template>
<script>
export default {
    name: 'AvatarHeader',
    props: {
        title: {
            type: String,
            default: ''
        }
    },
    methods: {
        trimName(store) {
            const words = store.split(' ');
            const initials = words.map(word => word.charAt(0));
            const result = initials.join('');
            return result;
        }
    }
}
</script>
<style lang="scss" scoped>
.avatarHeader {
    cursor: pointer;
}
</style>