export function isMobileDevice() {
    const mediaQuery = window.matchMedia('(max-width: 1024px)');
    return mediaQuery.matches;
}

export function formatNumber(number) {
    const price = String(number);
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}


export default {
    isMobileDevice,
    formatNumber
}