<template>
    <div class="titlePage">
        <v-container fluid>
            <h2>{{ title }}</h2>
        </v-container>
    </div>
</template>
<script>
export default {
    name: 'TitlePage',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
.titlePage {
    text-align: left!important;;
}
</style>