<template>
    <TitlePage title="Edit Produk"/>
    <CardContent
        :isUseback="successSave"
        @action-back="backToList"
    >
        <div class="loding" v-if="loading">
            <v-progress-circular class="mt-2" color="primary" model-value="20" indeterminate></v-progress-circular>
        </div>
        <div class="form-edit" v-else>
            <v-row >
                <v-col cols="12" md="6" lg="6">
                    <v-text-field
                        label="Nama Produk"
                        class="input-form"
                        variant="underlined"
                        v-model="form.namaProduk"
                    ></v-text-field>

                    <v-select
                        label="Kategori"
                        :items="parentKategori"
                        item-title="namaKategori"
                        item-value="kategoriId"
                        v-model="selectedKategori"
                        return-object
                        variant="underlined"
                    ></v-select>

                    <v-select
                        :label="loadingSub ? 'Loading...' : 'Sub Kategori'"
                        :items="subKategori"
                        item-title="namaSubKategori"
                        item-value="subKategoriId"
                        v-model="selectedSubKategori"
                        return-object
                        variant="underlined"
                    ></v-select>

                    <v-text-field
                        label="Harga Normal"
                        class="input-form"
                        variant="underlined"
                        type="number"
                        v-model="form.hargaNormal"
                    ></v-text-field>

                    <v-text-field
                        label="Harga Promo"
                        class="input-form"
                        variant="underlined"
                        type="number"
                        v-model="form.hargaPromo"
                    ></v-text-field>

                </v-col>
                <v-col cols="12" md="6" lg="6">
                    <v-text-field
                        label="Stok"
                        class="input-form"
                        variant="underlined"
                        type="number"
                        v-model="form.qtyStock"
                    ></v-text-field>

                    <v-text-field
                        label="Berat (Dalam gram)"
                        class="input-form"
                        variant="underlined"
                        type="number"
                        v-model="form.beratGram"
                    ></v-text-field>

                    <v-textarea 
                        label="Deskripsi" 
                        variant="underlined"
                        class="input-form"
                        row="7"
                        v-model="form.deskripsi"
                    ></v-textarea>

                    <v-file-input
                        :rules="rules"
                        accept="image/png, image/jpeg, image/bmp"
                        placeholder="Pilih Gambar produk"
                        prepend-icon="mdi-camera"
                        label="Tambah Gambar Produk"
                    ></v-file-input>
                </v-col>
            </v-row>

            <div class="d-flex flex-row-reverse">
                <v-sheet>
                    <v-btn  
                        color="info"
                        @click="editingProduct"
                        :disabled="loadingSave"
                    >
                        <v-icon icon="mdi-content-save"></v-icon>
                        {{ loadingSave ? 'Menyimpan...' : 'Update Produk' }}
                    </v-btn>
                </v-sheet>
            </div>
        </div>
        
        
    </CardContent>
</template>
<script>
import TitlePage from '@/components/TitlePage.vue';
import CardContent from '@/components/CardContent.vue';
import { getParentKategori, getSubKategori, createProduct, getProdukById, editProduct } from '@/api/ApiService.js';

export default {
    name: 'ProductPageEdit',
    data() {
        return {
            form: {
                beratGram: null,
                deskripsi: null,
                hargaNormal: null,
                hargaPromo: null,
                kategoriId: null,
                namaProduk: null,
                qtyStock: null,
                subKategoriId: null,
                tokoId: null,

                isHargaPromo: true,
                produkId: null
            },
            udi: {
                "beratGram": 0,
                "hargaNormal": 0,
                "hargaPromo": 0,
                "isHargaPromo": true,
                "produkId": "string",
                "qtyStock": 0
            },
            selectedKategori: null,
            selectedSubKategori: null,
            parentKategori: [],
            subKategori: [],
            loadingSub: false,
            rules: [
                value => {
                    return !value || !value.length || value[0].size < 2000000 || 'Avatar size should be less than 2 MB!'
                },
            ],
            successSave: false,
            loadingSave: false,
            detailProduct: null,
            loading: false
        }
    },
    watch: {
        selectedKategori: {
            deep: true,
            handler: function(value) {
                const kategoriId = value?.kategoriId;
                this.form.kategoriId = kategoriId;
                this.fetchSubKategori(kategoriId);
            }
        },
        selectedSubKategori: {
            deep: true,
            handler: function(value) {
                const subKategoriId = value?.subKategoriId;
                this.form.subKategoriId = subKategoriId;
            }
        }
    },
    components: {
        TitlePage,
        CardContent
    },
    mounted() {
        this.fetchParentKategori();
        this.fetchDetailProduct();
        this.form.tokoId = this.storeId;
    },
    methods: {
        async fetchDetailProduct() {
            const id = this.$route.params.id;
            this.loading = true;
            try {
                const res = await getProdukById(id);
                this.detailProduct = res.data.data;
                this.form.beratGram = this.detailProduct?.beratGram;
                this.form.deskripsi = this.detailProduct?.deskripsi;
                this.form.hargaNormal = this.detailProduct?.hargaNormal;
                this.form.hargaPromo = this.detailProduct?.hargaPromo;
                this.form.kategoriId = this.detailProduct?.kategoriId;
                this.form.namaProduk = this.detailProduct?.namaProduk;
                this.form.qtyStock = this.detailProduct?.qtyStock;
                this.form.produkId = this.detailProduct?.produkId;
                this.form.subKategoriId = this.detailProduct?.subKategoriId;
                const kategori = this.parentKategori.find(item => item.kategoriId === this.form.kategoriId);
                console.log(kategori)
                this.selectedKategori = kategori;
                this.fetchSubKategori(this.form.kategoriId)
                this.selectedSubKategori = {
                    namaSubKategori: this.detailProduct?.namaSubKategori,
                    subKategoriId: this.detailProduct?.subKategoriId,
                    kategoriId: this.detailProduct?.kategoriId,
                    namaKategori: this.detailProduct?.namaKategori
                }
                this.loading = false;
            } catch(e) {
                console.error(e);
            }
        },
        async fetchParentKategori() {
            const payload= {
                pageNumber: 1,
                pageSize: 100,
                sortBy: 'namaKategori',
                sortDirection: 'asc'
            };
            try {
                const res = await getParentKategori(payload);
                this.parentKategori = res.data.data.data;
            } catch(e) {
                console.error(e);
            }
        },
        async fetchSubKategori(kategoriId) {
            const payload= {
                pageNumber: 1,
                pageSize: 100,
                sortBy: 'namaSubKategori',
                sortDirection: 'asc',
                kategoriId: kategoriId
            };
            this.loadingSub = true;
            try {
                const res = await getSubKategori(payload);
                this.subKategori = res.data.data.data;
                this.loadingSub = false;
            } catch(e) {
                console.error(e);
            }
        },
        async saveProduct() {
            this.loadingSave = true;
            try {
                await createProduct(this.form)
                this.successSave = true;
                this.loadingSave = false
            } catch(e) {
                console.error(e);
            }
        },
        backToList() {
            this.$router.push({name: 'ProductPage'});
        },
        async editingProduct() {
            this.loadingSave = true;
            try {
                await editProduct(this.form)
                this.successSave = true;
                this.loadingSave = false
                this.$router.push({name: 'ProductPage'})
            } catch(e) {
                console.error(e);
            }
        }
    },
    computed: {
        storeId() {
            let storeId = null;
            const storeProfile = this.$store.getters['getStoreProfile'];
            storeId = storeProfile?.tokoId;
            return storeId;
        }
    }
}
</script>
<style lang="scss" scoped>
.input-form {
    margin-bottom: 16px;
}

.loding {
    width: 100%;
    text-align: center;
    padding: 120px 0px;
}
</style>