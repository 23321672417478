<template>
    <TitlePage title="Data Produk"/>

    <v-container fluid>
        <div class="d-flex flex-row-reverse">
            <v-sheet>
                <v-btn  
                    color="info"
                    @click="addProduct"
                >
                    <v-icon icon="mdi-plus"></v-icon>
                    Tambah Produk
                </v-btn>
            </v-sheet>
        </div>
    </v-container>
    
    <CardContent>
        <v-table density="compact" hover>
            <thead>
                <tr>
                    <th class="text-left">
                        Nama Produk
                    </th>
                    <th class="text-left">
                        Stok
                    </th>
                    <th class="text-left">
                        Harga Normal
                    </th>
                    <th class="text-left">
                        Status
                    </th>
                    <th class="text-left">
                    </th>
                </tr>
            </thead>
            <tbody v-if="loadingProduk">
                <tr>
                    <td colspan="5" style="text-align: center;">
                        <v-progress-circular class="mt-2" color="primary" model-value="20" indeterminate></v-progress-circular>
                    </td>
                </tr>
            </tbody>

            <tbody v-else>
                <tr v-if="dataProduk.length == 0">
                    <td colspan="5" style="text-align: center;">Belum ada data</td>
                </tr>
                <tr 
                    v-for="(item, index) in dataProduk"
                    :key="index"
                    v-else
                >
                    <td>{{ item.namaProduk }}</td>
                    <td>{{ item.qtyStock }}</td>
                    <td>{{ item.hargaNormal }}</td>
                    <td> 
                        <v-chip size="small" color="success" v-if="item.status === 'AKTIF'">
                            {{ item.status }}
                        </v-chip>
                        <v-chip size="small" color="warning" v-else>
                            {{ item.status }}
                        </v-chip>
                    </td>
                    <td>
                        <v-btn 
                            variant="text" 
                            density="compact" 
                            icon="mdi-eye-outline" 
                            color="warning"
                            @click="detailProduct(item.produkId)"
                        >
                        </v-btn>
                        <v-btn 
                            variant="text" 
                            density="compact" 
                            icon="mdi-square-edit-outline" 
                            color="info"
                            @click="editProduct(item.produkId)"
                        >
                        </v-btn>
                        <v-btn 
                            variant="text" 
                            density="compact" 
                            icon="mdi-delete-outline" 
                            color="error"
                            @click="confirmDelete(item)"
                        >
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </v-table>
    </CardContent>

    <ModalComponent 
        v-if="openDeleteDialog"
    >
        <v-card
            class="mx-auto my-8"
            max-width="344"
            elevation="16"
        >
            <div class="dialogDelete">
                <div class="title">Hapus Produk</div>
                <div class="content-dialog">Apakah anda ingin menghapus produk {{dataDelete?.namaProduk}}?</div>
                <div class="button-options">
                    <v-btn 
                        variant="text" 
                        color="info"
                        @click="openDeleteDialog = false"
                    >
                        Tidak
                    </v-btn>
                    <v-btn 
                        variant="flat" 
                        color="info"
                        @click="deleteProductAction"
                        :disabled="loadingDelete"
                    >
                        Ya, Hapus
                    </v-btn>
                </div>
            </div>
        </v-card>
    </ModalComponent>
</template>

<script>
import TitlePage from '@/components/TitlePage.vue';
import CardContent from '@/components/CardContent.vue';
import { getProdukByToko, deleteProduct } from '@/api/ApiService.js';
import ModalComponent from '@/components/ModalComponent.vue';

export default {
    name: 'ProductPage',
    data () {
      return {
        payload: {
            pageNumber: 1,
            pageSize: 10,
            sortDirection: 'desc',
            tokoId: null
        },
        dataProduk: [],
        loadingProduk: false,
        openDeleteDialog: false,
        dataDelete: {},
        loadingDelete: false
      }
    },
    components: {
        TitlePage,
        CardContent,
        ModalComponent
    },
    mounted() {
        this.payload.tokoId = this.storeID;
        this.fetchProduk();
        if (this.payload.tokoId == undefined){
            setTimeout(()=> {
                this.payload.tokoId = this.storeID;
                this.fetchProduk();
            }, 2000)
        }
    },
    methods: {
        async fetchProduk() {
            this.loadingProduk = true;
            try {
                const res = await getProdukByToko(this.payload);
                this.dataProduk = res.data.data;
                this.loadingProduk = false;
            } catch(e) {
                console.error(e);
            }
        },
        addProduct() {
            this.$router.push({name: 'ProductPageAdd'});
        },
        detailProduct(idProduct) {
            this.$router.push({name: 'ProductPageDetail', params: {id: idProduct}});
        },
        editProduct(idProduct) {
            this.$router.push({name: 'ProductPageEdit', params: {id: idProduct}});
        },
        confirmDelete(product) {
            this.openDeleteDialog = true;
            this.dataDelete = product;
        },
        async deleteProductAction() {
            const payload = {
                produkId: this.dataDelete?.produkId
            }
            this.loadingDelete = true;
            try {
                await deleteProduct(payload);
                this.openDeleteDialog = false;
                this.loadingDelete = false;
                this.fetchProduk();
            } catch(e) {
                console.error(e)
            }

        }

    },
    computed: {
        storeID() {
            const store = this.$store.getters['getStoreProfile'];
            return store?.tokoId;
        }
    }
}
</script>

<style lang="scss" scoped>
.dialogDelete {
    padding: 15px;
    .title {
        font-weight: 600;
        margin-bottom: 8px;
    }
    .content-dialog {
        font-weight: 400;
        margin-bottom: 15px;
    }
    .button-options {
        text-align: right;
    }
}
</style>