<template>
<div>
    <div class="logo-login">
        <span class="title-logo">Daftar Penjual Olebsai</span>
    </div>
    <v-card
        class="mx-auto pa-12 pb-8"
        elevation="8"
        max-width="448"
        rounded="lg"
    >
        <div class="text-subtitle-1 text-medium-emphasis">Nama Pemilik Toko</div>
        <v-text-field
            density="compact"
            placeholder="Input Nama Pemilik Toko"
            variant="outlined"
            v-model="form.namaUser"
        ></v-text-field>

        <div class="text-subtitle-1 text-medium-emphasis">Nama Toko</div>
        <v-text-field
            density="compact"
            placeholder="Input Nama Toko"
            variant="outlined"
            v-model="form.namaToko"
        ></v-text-field>

        <div class="text-subtitle-1 text-medium-emphasis">No Handphone</div>
        <v-text-field
            density="compact"
            placeholder="Input No Handphone"
            variant="outlined"
            v-model="form.nomorHp"
        ></v-text-field>

        <div class="text-subtitle-1 text-medium-emphasis">Email</div>
        <v-text-field
            density="compact"
            placeholder="Input email"
            prepend-inner-icon="mdi-email-outline"
            variant="outlined"
            v-model="form.email"
        ></v-text-field>

        <div class="text-subtitle-1 text-medium-emphasis">Password</div>
        <v-text-field
            :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
            :type="visible ? 'text' : 'password'"
            density="compact"
            placeholder="Input password"
            prepend-inner-icon="mdi-lock-outline"
            variant="outlined"
            @click:append-inner="visible = !visible"
            v-model="form.password"
        ></v-text-field>

        <div class="text-subtitle-1 text-medium-emphasis">Konfirmasi Password</div>
        <v-text-field
            :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
            :type="visible ? 'text' : 'password'"
            density="compact"
            placeholder="Input password"
            prepend-inner-icon="mdi-lock-outline"
            variant="outlined"
            @click:append-inner="visible = !visible"
            v-model="form.passwordConfirm"
        ></v-text-field>

        <v-btn
            block
            class="mb-8"
            color="primary"
            size="large"
            variant="tonal"
            :disabled="disableBtn"
            @click="registerSeller"
        >
        
            {{ loading ? 'Loading...' : 'Daftar' }}
        </v-btn>
    </v-card>
</div>
</template>
<script>
import { registerWeb } from '@/api/ApiService.js';

export default {
    name: 'RegisterPage',
    data() {
        return {
            form: {
                email: '',
                namaToko: '',
                namaUser: '',
                nomorHp: '',
                password: '',
            },
            passwordConfirm: '',
            visible: false,
            loading: false,
            disableBtn: true
        }
    },
    watch: {
        form: {
            deep: true,
            handler: function(inputValue) {
                console.log(inputValue.namaToko.length)
                if (inputValue.namaUser !== '' && 
                    inputValue.namaUser.length > 3 && 
                    inputValue.namaToko !== '' && 
                    inputValue.namaToko.length > 3 &&
                    inputValue.nomorHp !== '' && 
                    inputValue.nomorHp.length > 7 &&
                    inputValue.email !== '' && 
                    inputValue.email.length > 5 &&
                    inputValue.password !== '' && 
                    inputValue.password.length > 5 &&
                    inputValue.password == inputValue.passwordConfirm
                ) {
                    this.disableBtn = false
                } else {
                    this.disableBtn = true
                }
            }
        }
    },
    methods: {
        async registerSeller() {
            try {
                delete this.form.passwordConfirm;
                const res = await registerWeb(this.form);
                console.log(res)
                // this.$router.push({name: 'loginPage'});
                this.$router.push(`/verifikasi-otp?email=${this.form.email}`);
            } catch (e) {
                console.error(e);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.logo-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15vh;
  
  img {
    height: 40px;
  }
  .title-logo {
    font-size: 18px;
    font-weight: bold;
  }
}
</style>