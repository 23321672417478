<template>
<v-dialog
    v-model="defaultOpen"
    persistent
    width="auto"
>
    <slot />
</v-dialog>
</template>
<script>
export default {
    name: "ModalComponent",
    data() {
        return {
            defaultOpen: true
        }
    }
    
}
</script>