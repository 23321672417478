import { createStore } from 'vuex'

export default createStore({
  state: {
    sideBar: true,
    storeProfile: null
  },
  mutations: {
    setSideBar(state, data) {
      state.sideBar = data;
    },
    setStoreProfile(state, data) {
      state.storeProfile = data;
    }
  },
  actions: {
  },
  getters: {
    getSideBar: state => state.sideBar,
    getStoreProfile: state => state.storeProfile
  },
  modules: {
  }
})
