import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LayoutDashboard from '../layouts/LayoutDashboard.vue'
import loginPage from '../views/LoginPage.vue'
import RegisterPage from '../views/RegisterPage.vue'
import OtpPage from '../views/OtpPage.vue'
import ProductPage from '@/views/ProductPage.vue'
import ProductPageDetail from '@/views/ProductPageDetail.vue'
import ProductPageAdd from '@/views/ProductPageAdd.vue'
import ProductPageEdit from '@/views/ProductPageEdit.vue'
import PesananPage from '@/views/PesananPage.vue'
import ProfilePage from '@/views/ProfilePage.vue'

import { getToken } from '../api/ApiService.js'

let token = getToken();

const routes = [
  {
    path: '/',
    name: 'LayoutDashboard',
    component: LayoutDashboard,
    redirect: "/dashboard",
    children: [
      {
        path: 'dashboard',
        name: 'HomeView',
        component: HomeView,
        beforeEnter: (to, from, next) => {
          const isAuthenticated = token !== null;
          if (isAuthenticated) {
            next();
          } else {
            next('login');
          }
        },
      },
      {
        path: 'products',
        name: 'ProductPage',
        component: ProductPage,
        beforeEnter: (to, from, next) => {
          const isAuthenticated = token !== null;
          if (isAuthenticated) {
            next();
          } else {
            next('login');
          }
        },
      },
      {
        path: 'product/add',
        name: 'ProductPageAdd',
        component: ProductPageAdd,
        beforeEnter: (to, from, next) => {
          const isAuthenticated = token !== null;
          if (isAuthenticated) {
            next();
          } else {
            next('login');
          }
        },
      },
      {
        path: 'product/:id/detail',
        name: 'ProductPageDetail',
        component: ProductPageDetail,
        beforeEnter: (to, from, next) => {
          const isAuthenticated = token !== null;
          if (isAuthenticated) {
            next();
          } else {
            next('login');
          }
        },
      },
      {
        path: 'product/:id/edit',
        name: 'ProductPageEdit',
        component: ProductPageEdit,
        beforeEnter: (to, from, next) => {
          const isAuthenticated = token !== null;
          if (isAuthenticated) {
            next();
          } else {
            next('login');
          }
        },
      },
      {
        path: 'orders',
        name: 'PesananPage',
        component: PesananPage,
        beforeEnter: (to, from, next) => {
          const isAuthenticated = token !== null;
          if (isAuthenticated) {
            next();
          } else {
            next('login');
          }
        },
      },
      {
        path: 'profile',
        name: 'ProfilePage',
        component: ProfilePage,
        beforeEnter: (to, from, next) => {
          const isAuthenticated = token !== null;
          if (isAuthenticated) {
            next();
          } else {
            next('login');
          }
        },
      },
    ]
  },
  {
    path: '/login',
    name: 'loginPage',
    component: loginPage,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = token !== null;
      if (isAuthenticated) {
        next('/dashboard');
      } else {
        next();
      }
    },
  },
  {
    path: '/register',
    name: 'RegisterPage',
    component: RegisterPage,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = token !== null;
      if (isAuthenticated) {
        next('/dashboard');
      } else {
        next();
      }
    },
  },
  {
    path: '/verifikasi-otp',
    name: 'OtpPage',
    component: OtpPage,
    beforeEnter: (to, from, next) => {
      const isAuthenticated = token !== null;
      if (isAuthenticated) {
        next('/dashboard');
      } else {
        next();
      }
    },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
