<template>
    <v-layout>
      <SideBar :isOpen="navigation"/>
  
      <HeaderNav 
        @app-icon-click="triggerNavbar"
        :storeName="storeDetail?.namaToko"
      />
  
      <v-main style="min-height: 300px;">
        <router-view></router-view>
      </v-main>
    </v-layout>
  </template>
<script>
import HeaderNav from '@/components/HeaderNav.vue';
import SideBar from '@/components/SideBar.vue';
import { isMobileDevice } from '../library/util.js';
import { getTokoByUser } from '@/api/ApiService.js';

export default {
    name: 'LayoutDashboard',
    data() {
        return {
          order: 0,
          navigation: true
        }
    },
    mounted() {
      const isMobile = isMobileDevice();
      if (isMobile) {
        this.navigation = false;
      }
      this.fetchProfileToko();
    },
    components: {
      HeaderNav,
      SideBar
    },
    methods: {
      triggerNavbar() {
        this.navigation = !this.navigation;
        this.$store.commit('setSideBar', this.navigation)
      },
      async fetchProfileToko() {
        try {
          const res = await getTokoByUser();
          this.$store.commit('setStoreProfile', res.data.data);
        } catch(e) {
          console.error(e);
        }
      }
    },
    computed: {
      storeDetail() {
        return this.$store.getters['getStoreProfile'];
      }
    }
}
</script>