<template>
    <div>
        <div class="logo-login">
            <span class="title-logo">Verifikasi OTP</span>
        </div>
        <v-card
            class="mx-auto pa-12 pb-8"
            elevation="8"
            max-width="448"
            rounded="lg"
        >
            <v-alert
                type="error"
                variant="outlined"
                class="mb-4"
                v-if="showFailed"
            >
                {{ errorMessage }}
            </v-alert>

            <div class="text-subtitle-1 text-medium-emphasis">Email</div>
            <v-text-field
                density="compact"
                placeholder="Input email"
                prepend-inner-icon="mdi-email-outline"
                variant="outlined"
                v-model="email"
            ></v-text-field>

            <div class="text-subtitle-1 text-medium-emphasis">OTP</div>
            <v-text-field
                density="compact"
                placeholder="Input OTP"
                variant="outlined"
                v-model="otp"
            ></v-text-field>

            <v-btn
                block
                class="mb-8"
                color="primary"
                size="large"
                variant="tonal"
                :disabled="disableBtn"
                @click="loginAction"
            >
            
                {{ loading ? 'Loading...' : 'Verifikasi' }}
            </v-btn>
        </v-card>
    </div>
</template>
<script>
import { loginOTP } from '@/api/ApiService.js';

export default {
    name: 'OtpPage',
    data() {
        return {
            email: '',
            otp: '',
            loading: false,
            disableBtn: true,
            errorMessage: '',
            showFailed: false
        }
    },
    watch: {
        email: function(val) {
            if (val !== '' && this.otp !== '') {
                this.disableBtn = false
            } else {
                this.disableBtn = true
            }
        },
        otp: function(val) {
            if (val !== '' && this.email !== '') {
                this.disableBtn = false
            } else {
                this.disableBtn = true
            }
        }
    },
    mounted() {
        let emailQu = this.$route.query.email;
        if(emailQu) {
            this.email = emailQu
        }
    },
    methods: {
        async loginAction() {
            const payload = {
                email: this.email,
                otp: this.otp
            }
            this.loading = true;
            try {
                const res = await loginOTP(payload)
                const data = res.data;
                const token = data.data;
                this.$cookies.set('_token', token);
                console.log(res)
                
                setTimeout(() => {
                    this.loading = false;
                    this.$router.push({ path: '/dashboard' })
                }, 1000)
                
                // if (data.otpFlag) {
                //     this.$cookies.set('_token', token);
                //     this.$router.push({ path: '/dashboard' })
                // } else {
                //     this.$router.push(`/verifikasi-otp?email=${this.email}`);
                // }
                
            } catch(e) {
                this.loading = false;
                this.showFailed = true;
                this.errorMessage = e.message;
                setTimeout(() => {
                    this.showFailed = false;
                    this.errorMessage = ''
                }, 5000)
                
                console.log(e)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.logo-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15vh;
  
  img {
    height: 40px;
  }
  .title-logo {
    font-size: 18px;
    font-weight: bold;
  }
}
</style>